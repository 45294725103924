import React from 'react'
import Contact from '../../components/Contact';

const ContactPage = () => {
    return (
        <div>
            <Contact/>
        </div>
    )
}

export default ContactPage
