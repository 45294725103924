import React from 'react';
import Home from '../../components/Home';

const HomePage = () => {
    return (
        <Home/>
    )
}

export default HomePage
